import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material'
import { Close } from 'assets/images'
import Button from 'components/Button/Index'
import Loader from 'components/Loader/Index'
import Textfield from 'components/Textfield/Index'
import { establishmentList } from 'features/establishment/establishment'
import { selectedRestaurant } from 'features/restaurant/restaurant'
import { useFormik } from 'formik'
import { Form, IGMAddFormRequest } from 'interfaces/guestmarketing'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import EmailEditor from 'react-email-editor'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import guestMarketingService from 'services/guest.marketing.service'
import { resolveHTTP } from 'utility/helper'
import * as yup from 'yup'

interface dialogProps {
  open: boolean
  isEdit: boolean
  isView: boolean
  formIdForEdit?: number
  onClose?: React.MouseEventHandler<Element>
  closeDialog?: (event: boolean) => void
}
function LeadGeneratorFormDialog(props: dialogProps) {
  const { open, isEdit, isView, formIdForEdit, onClose, closeDialog } = props
  const formEditorRef = useRef(null)
  const [loading, setLoading] = useState(true)
  const [temp, setTemp] = useState(null)
  const [jsonEditor, setJsonEditor] = useState(null)
  const allEstablishmentList = useSelector(establishmentList)
  const selectedRestaurantId = useSelector(selectedRestaurant)
  const [activeStep, setActiveStep] = useState(0)

  const filteredEstablishments = allEstablishmentList
    .filter((x) => x.restaurantId === selectedRestaurantId)
    .map((x) => x.establishmentId)

  const getTemplateHtml = async (): Promise<{
    design: string
    html: string
  }> => {
    const { editor } = formEditorRef.current
    if (editor) {
      return new Promise((resolve) => {
        editor.exportHtml((data) => {
          const { design, html } = data
          setTemp(html)
          setJsonEditor(design)
          resolve({ design, html })
        })
      })
    }
    return { design: '', html: '' }
  }

  useEffect(() => {
    // Set loading to false when the editor is ready
    if (formEditorRef.current) {
      setLoading(false)
    }
  }, [])

  const handleSaveForm = async (formdata, resetForm) => {
    const [data] = await resolveHTTP(guestMarketingService.addForm(formdata))
    if (data?.data === 'success') {
      toast.success('Form is successfully created')
      setActiveStep(0)
      if (resetForm != null) {
        resetForm()
      }
      setJsonEditor(null)
      setTemp(null)
      closeDialog(true)
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
    } else {
      toast.error('Something went wrong!')
    }
  }

  const handleNext = async (formdata, resetForm = null) => {
    switch (activeStep) {
      case 1: {
        const { design, html } = await getTemplateHtml()
        formdata.template = html
        formdata.templateDesign = JSON.stringify(design)

        if (isEdit) {
          const [data] = await resolveHTTP(
            guestMarketingService.editForm(formdata)
          )
          if (data?.data === 'success') {
            toast.success('Form is successfully updated')
            setActiveStep(0)
            resetForm()
            setJsonEditor(null)
            setTemp(null)
            closeDialog(true)
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
          } else {
            toast.error('Something went wrong!')
          }
        } else if (isView) {
          setActiveStep(0)
          resetForm()
          setJsonEditor(null)
          setTemp(null)
          closeDialog(true)
        } else {
          handleSaveForm(formdata, resetForm)
        }
        break
      }
      default:
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
        break
    }
  }

  const validationSchema = yup.object({
    formTitle: yup.string().required('Form Title is required'),
  })

  const CompareValidation = () => {
    return validationSchema
  }

  const handleSave = async (formdata: IGMAddFormRequest, resetForm) => {
    handleNext(formdata, resetForm)
  }

  const formik = useFormik<IGMAddFormRequest>({
    initialValues: {
      formId: 0,
      establishmentId: filteredEstablishments,
      formTitle: '',
      template: temp,
      templateDesign: jsonEditor,
      createdBy: Number(localStorage.getItem('loginId')) ?? null,
    },
    validationSchema: CompareValidation,
    onSubmit: (values, { resetForm }) => handleSave(values, resetForm),
  })

  const handleClose = (event: React.MouseEvent<Element>) => {
    onClose(event)
    formik.resetForm()
    setActiveStep(0)
    setJsonEditor(null)
    setTemp(null)
  }

  const getFormForEdit = useCallback(
    async (formId): Promise<Form | undefined> => {
      try {
        const [data] = await Promise.all([
          guestMarketingService.getFormForEdit({ formId }),
        ])
        return data?.data?.data as Form
      } catch (error) {
        console.error(error)
        return null
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    const fetchFormData = async () => {
      if ((isEdit || isView) && formIdForEdit > 0) {
        try {
          const response = await getFormForEdit(formIdForEdit)

          if (response) {
            // Set Formik values
            formik.setValues({
              formId: response.formId,
              establishmentId: [],
              formTitle: response.formTitle,
              template: response.template,
              templateDesign: response.templateDesign,
              createdBy: Number(localStorage.getItem('loginId')) ?? null,
            })

            // Load the design after Formik values have been updated
            setTimeout(() => {
              formEditorRef.current.editor.loadDesign(
                JSON.parse(response.templateDesign)
              )
            }, 0)
          }
        } catch (error) {
          console.error('Failed to fetch form data:', error)
        }
      }
    }

    fetchFormData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formIdForEdit, isEdit, isView])

  return (
    <>
      {/* lead generator form dialog start */}
      <Dialog
        className="primary-dialog full-height create-campaign-dialog"
        maxWidth="xl"
        fullWidth
        open={open}
      >
        <DialogTitle>
          <Button
            variant="text"
            title="Close"
            onClick={handleClose}
            className="icon-btn rounded"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        <DialogContent className="pb-0">
          <div className="stepper-wrapper">
            <Stepper activeStep={activeStep} alternativeLabel>
              <Step
                className={activeStep > 0 ? 'visited' : ''}
                key="Form Information"
                completed={false}
                onClick={() => {
                  if (activeStep >= 0) {
                    setActiveStep(0)
                  }
                }}
              >
                <StepLabel>Form Information</StepLabel>
              </Step>
              <Step
                className={activeStep > 1 ? 'visited' : ''}
                key="Form Content"
                completed={false}
                onClick={() => {
                  if (activeStep >= 1 || isEdit || isView) {
                    setActiveStep(1)
                  }
                }}
              >
                <StepLabel>Form Content</StepLabel>
              </Step>
            </Stepper>
            {/* stepper body */}
            <form onSubmit={formik.handleSubmit}>
              <div className="stepper-body">
                {/* form information start */}
                {activeStep === 0 && (
                  <div className="campaign-information">
                    <h4 className="title">Form Information</h4>
                    <p className="subtext">
                      Enter your details below to continue
                    </p>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <div className="form-group">
                          <Textfield
                            variant="outlined"
                            label="Form Title"
                            name="formTitle"
                            disable={isView}
                            value={formik.values.formTitle}
                            onChange={formik.handleChange}
                            error={
                              !!formik.errors.formTitle &&
                              formik.touched.formTitle
                            }
                            helperText={
                              !!formik.errors.formTitle &&
                              formik.touched.formTitle
                                ? formik.errors.formTitle
                                : ''
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          className="nav-btn"
                          variant="contained"
                          color="primary"
                          title={isView ? 'Next' : 'Save & Next'}
                          type="submit"
                        >
                          {isView ? 'Next' : 'Save & Next'}
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                )}
                {/* form information end */}
                {/* content builder start */}
                <div
                  className="custom-unlayer-editor"
                  style={
                    activeStep === 1
                      ? { display: 'block' }
                      : { display: 'none' }
                  }
                >
                  <div className="form">
                    <h4 className="title">Form Content</h4>
                    <p className="subtext">
                      Enter your details below to continue
                    </p>
                    <div
                      className="custom-unlayer-editor"
                      style={{ display: 'block' }}
                    >
                      {loading && <Loader />}
                      <EmailEditor
                        ref={formEditorRef}
                        onLoad={() => {
                          console.log('Editor loaded successfully!')
                        }}
                        minHeight="600px"
                        options={{
                          appearance: { theme: 'light' },
                          user: {
                            id: Number(localStorage.getItem('loginId')),
                          },
                          tools: {
                            form: {
                              enabled: true,
                              position: 0,
                              properties: {
                                fields: {
                                  value: [
                                    {
                                      name: 'email',
                                      type: 'email',
                                      label: 'Email',
                                      placeholder_text: 'Enter email here',
                                      show_label: true,
                                      required: true,
                                    },
                                    {
                                      name: 'first_name',
                                      type: 'text',
                                      label: 'First Name',
                                      placeholder_text: 'Enter first name here',
                                      show_label: true,
                                      required: true,
                                    },
                                    {
                                      name: 'last_name',
                                      type: 'text',
                                      label: 'Last Name',
                                      placeholder_text: 'Enter last name here',
                                      show_label: true,
                                      required: true,
                                    },
                                    {
                                      name: 'birth_day',
                                      type: 'date',
                                      label: 'Date of Birth',
                                      show_label: true,
                                      required: true,
                                    },
                                    {
                                      name: 'phone_number',
                                      type: 'text',
                                      label: 'Phone Number',
                                      placeholder_text:
                                        'Enter phone number here',
                                      show_label: true,
                                      required: true,
                                    },
                                    {
                                      name: 'zip_code',
                                      type: 'text',
                                      label: 'Postal Code',
                                      placeholder_text:
                                        'Enter postal code here',
                                      show_label: true,
                                      required: true,
                                    },
                                    {
                                      name: 'privacy_policy',
                                      type: 'checkbox',
                                      label: 'Privacy Policy',
                                      show_label: false,
                                      required: true,
                                      options: `<span>I've read and agree to <a href="https://fastabpayments.com/en/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></span>`,
                                    },
                                  ],
                                },
                              },
                            },
                            social: { enabled: false },
                            video: { enabled: false },
                            html: { enabled: false },
                            menu: { enabled: false },
                            timer: { enabled: false },
                          },
                          features: {
                            blocks: false,
                          },
                          displayMode: 'web',
                        }}
                        onReady={(unlayer) => {
                          console.log(unlayer)
                          setLoading(false)
                        }}
                        projectId={255562}
                      />
                    </div>
                    {isView ? (
                      <Button
                        className="nav-btn"
                        variant="outlined"
                        color="secondary"
                        type="submit"
                        title="Close"
                      >
                        Close
                      </Button>
                    ) : (
                      <Button
                        className="nav-btn"
                        variant="contained"
                        color="primary"
                        type="submit"
                        title={isEdit ? 'Update Form' : 'Save Form'}
                      >
                        {isEdit ? 'Update Form' : 'Send Form'}
                      </Button>
                    )}
                  </div>
                </div>
                {/* content builder end */}
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>
      {/* lead generator form dialog end */}
    </>
  )
}

export default LeadGeneratorFormDialog
