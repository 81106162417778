/* eslint-disable no-nested-ternary */
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { GridRenderCellParams, GridSortModel } from '@mui/x-data-grid-pro'
import { Close, ExportNew } from 'assets/images'
import Button from 'components/Button/Index'
import DataTable from 'components/DataTable/Index'
import { IDatagrid } from 'interfaces/datagrid'
import React, { useCallback, useEffect, useState } from 'react'
import {
  CampaignEmailDetails,
  ICampaignIdForEmailRequest,
} from 'interfaces/guestmarketing'
import guestMarketingService from 'services/guest.marketing.service'
import Moment from 'react-moment'
import moment from 'moment'
import { toast } from 'react-toastify'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
  campaignId?: number
  isForOpen: boolean
  isForUnsubscribe: boolean
}

function CampaignEmailDetailsDialog(props: dialogProps) {
  const { open, onClose, campaignId, isForOpen, isForUnsubscribe } = props
  const [dataGridOptions, setDataGridOptions] = useState<IDatagrid>({
    pageNumber: 1,
    pageSize: 50,
    sortOrder: [
      {
        field: 'emailSentAt',
        sort: 'desc',
      },
    ],
  })
  const [campEmailDetails, setCampEmailDetails] =
    useState<CampaignEmailDetails[]>()
  const [totalRecords, setTotalRecords] = useState(0)

  const onPageChange = (value: number) => {
    const options = { ...dataGridOptions }
    options.pageNumber = value
    setDataGridOptions(options)
  }

  const onPageSizeChange = (value: number) => {
    const options = { ...dataGridOptions }
    options.pageSize = value
    setDataGridOptions(options)
  }

  const onSortChange = (sortModel: GridSortModel) => {
    const options = { ...dataGridOptions }
    options.sortOrder = sortModel
    setDataGridOptions(options)
  }

  const getCampaignEmailDetails = useCallback(
    async (payload: ICampaignIdForEmailRequest) => {
      try {
        const data = await guestMarketingService.getCampaignEmailDetails(
          payload
        )
        setCampEmailDetails(data?.data?.data?.data)
        console.log(campEmailDetails)
        setTotalRecords(data?.data?.data?.totalRecords)
      } catch (e) {
        console.log(e)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const exportToCsv = () => {
    if (!campEmailDetails || campEmailDetails.length === 0) {
      toast.error('No data to export')
      return
    }

    // Prepare CSV data
    const headers = ['Email Address', 'Email Sent At']
    const csvData = campEmailDetails.map((row) => [
      row.emailId,
      moment(row.emailSentAt).format('MM/DD/YYYY hh:mm A'),
    ])

    // Combine headers and data
    const csvContent = [headers, ...csvData]
      .map((e) => e.map(String).join(','))
      .join('\n')

    // Create and download file
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute(
        'download',
        `${isForOpen ? 'Opened' : 'Unsubscribed'}_Emails.csv`
      )
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  useEffect(() => {
    if (open) {
      getCampaignEmailDetails({
        campaignId,
        isForOpen,
        isForUnsubscribe,
        pageSize: dataGridOptions.pageSize,
        pageNo: dataGridOptions.pageNumber,
        sortGridModels: dataGridOptions.sortOrder.map((d) => {
          return {
            field: d.field,
            sort: d.sort === 'asc' ? 1 : 0,
          }
        }),
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, dataGridOptions, open])

  return (
    <>
      <Dialog
        className="primary-dialog details-dialog"
        maxWidth={false}
        fullWidth
        open={open}
        onClose={onClose}
      >
        <DialogTitle>
          <h5>{isForOpen ? 'Opened Emails' : 'Unsubscribed Emails'}</h5>
          <Button
            variant="text"
            title="Close"
            onClick={onClose}
            className="icon-btn rounded"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        <DialogContent>
          <div
            className="flex items-center mb-3"
            style={{ paddingBottom: '15px' }}
          >
            <Button
              variant="outlined"
              color="inherit"
              disableFocusRipple
              title="Export"
              size="large"
              className="ml-auto btn-h-40"
              onClick={exportToCsv}
            >
              <img src={ExportNew} alt="Export" />
              <span>Export</span>
            </Button>
          </div>
          <div className="card mb-30 p-0" data-aos="fade-up">
            {/* data-table start */}
            <DataTable
              uniqueId="campaignInviteId"
              columnsData={[
                {
                  field: 'emailId',
                  headerName: 'Email Address',
                  flex: 1,
                  minWidth: 120,
                  sortable: false,
                  renderCell: (d: GridRenderCellParams<string>) => {
                    return d.value
                  },
                },
                {
                  field: 'emailSentAt',
                  headerName: 'Email Sent At',
                  flex: 1,
                  minWidth: 120,
                  sortable: false,
                  renderCell: (params: GridRenderCellParams<Date>) => {
                    return (
                      <Moment format="MM/DD/YYYY hh:mm A">
                        {params.value}
                      </Moment>
                    )
                  },
                },
              ]}
              rowsData={campEmailDetails}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              onSortModelChange={onSortChange}
              totalRecords={totalRecords}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default CampaignEmailDetailsDialog
