import { AxiosResponse } from 'axios'
import { IApiSuccessResponse } from 'interfaces/api-success-response'
import {
  IPos,
  IRestaurant,
  IRestaurantDetailData,
  IRestaurantRequest,
  IRestuarantSearchRequest,
  IRestuarantSearchResponse,
  ISearchResponse,
  ICsvDownload,
  IRestaurantCsvRequest,
  IRestaurantPOSs,
} from 'interfaces/restaurant'
import httpClient from './base-service'

const endPointBaseURL = `/Restaurant`
const endPointBaseURLTimeZone = `/TimeZone`

// get filtered restraunt list
const getFilteredResturants = async (
  requestBody: IRestuarantSearchRequest
): Promise<
  AxiosResponse<
    IApiSuccessResponse<ISearchResponse<IRestuarantSearchResponse[]>>
  >
> =>
  httpClient.post<
    IApiSuccessResponse<ISearchResponse<IRestuarantSearchResponse[]>>
  >(`${endPointBaseURL}/get-restaurant-list`, requestBody)

const getAllResturants = async (
  requestBody: IRestuarantSearchRequest
): Promise<
  AxiosResponse<
    IApiSuccessResponse<ISearchResponse<IRestuarantSearchResponse[]>>
  >
> =>
  httpClient.post<
    IApiSuccessResponse<ISearchResponse<IRestuarantSearchResponse[]>>
  >(`${endPointBaseURL}/get-restaurant-list`, requestBody)

const getResturantById = async (
  requestBody: IRestaurantRequest
): Promise<AxiosResponse<IApiSuccessResponse<IRestaurantDetailData>>> =>
  httpClient.post<IApiSuccessResponse<IRestaurantDetailData>>(
    `${endPointBaseURL}/get-restaurant-details`,
    requestBody
  )

const getPosList = async (): Promise<
  AxiosResponse<IApiSuccessResponse<IPos[]>>
> =>
  httpClient.get<IApiSuccessResponse<IPos[]>>(`${endPointBaseURL}/get-all-pos`)

const createRestaurent = async (
  requestBody: IRestaurant
): Promise<AxiosResponse<IApiSuccessResponse<IRestaurant>>> =>
  httpClient.post<IApiSuccessResponse<IRestaurant>>(
    `${endPointBaseURL}/create-restaurant`,
    requestBody
  )

const updateRestaurant = async (
  requestBody: IRestaurant
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.put<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/update-restaurant`,
    requestBody
  )

const deleteRestaurant = async (
  requestBody: IRestaurantRequest
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.delete<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/delete-restaurant`,
    {
      headers: {},
      data: requestBody,
      hideLoader: false,
    }
  )

const getTimeZone = async (): Promise<AxiosResponse> =>
  httpClient.get(`${endPointBaseURLTimeZone}/get-all-timezone`)

// download csv
const downloadCsv = async (
  requestBody: IRestaurantCsvRequest
): Promise<AxiosResponse<IApiSuccessResponse<ICsvDownload>>> =>
  httpClient.post<IApiSuccessResponse<ICsvDownload>>(
    `${endPointBaseURL}/create-csv-file-for-restaurant`,
    requestBody
  )

const GetOmnivorePosName = async (): Promise<
  AxiosResponse<IApiSuccessResponse<IRestaurantPOSs>>
> =>
  httpClient.get<IApiSuccessResponse<IRestaurantPOSs>>(
    `/Common/get-omnivore-pos-name`
  )

const GetMismatchedPayments = async (): Promise<
  AxiosResponse<IApiSuccessResponse<string>>
> =>
  httpClient.get<IApiSuccessResponse<string>>('/Common/get-mismatched-payments')

export default {
  getAllResturants,
  getResturantById,
  createRestaurent,
  updateRestaurant,
  getPosList,
  deleteRestaurant,
  getFilteredResturants,
  getTimeZone,
  downloadCsv,
  GetOmnivorePosName,
  GetMismatchedPayments,
}
