/* eslint-disable react/prefer-stateless-function */
import {
  GridColDef,
  GridSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid-pro'
import DataTable from 'components/DataTable/Index'
import React from 'react'

export enum EnumSortingType {
  Ascending = 0,
  Descending = 1,
}

export interface IPaging {
  pageNo: number
  pageSize: number
  sortOrder: EnumSortingType
}

interface IFasTabGridProps {
  uniqueId?: string
  columnsData: GridColDef[]
  gridData: Array<Object>
  checkboxSelection?: boolean
  totalRecords?: number
  onGridPageChange?: (value: number) => void
  onGridPageSizeChange?: (value: number) => void
  onGridSortChange?: (sortModel: GridSortModel) => void
  onSelectionModelChange?: (selectionModel: GridSelectionModel) => void
  classes?: string
  usePagination?: boolean
}

const FasTabGrid: React.FC<IFasTabGridProps> = ({
  uniqueId,
  columnsData,
  gridData = [],
  totalRecords = 0,
  onGridPageChange,
  onGridPageSizeChange,
  onGridSortChange,
  checkboxSelection,
  onSelectionModelChange,
  classes,
  usePagination = true,
}) => {
  return (
    <>
      <DataTable
        uniqueId={uniqueId}
        checkboxSelection={checkboxSelection}
        columnsData={columnsData}
        onPageChange={onGridPageChange}
        onPageSizeChange={onGridPageSizeChange}
        onSortModelChange={onGridSortChange}
        rowsData={gridData}
        totalRecords={totalRecords}
        onSelectionModelChange={onSelectionModelChange}
        classes={classes}
        bottomPagination={!usePagination}
      />
    </>
  )
}
export default FasTabGrid
