import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button as MUIButton,
  Typography,
  Box,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { toast } from 'react-toastify'

interface DialogProps {
  open: boolean
  scriptId: string
  onClose: () => void
}

function GenerateScriptDialog({ open, scriptId, onClose }: DialogProps) {
  const [scriptGenerated, setScriptGenerated] = useState('')

  useEffect(() => {
    // Generate the script dynamically based on scriptId
    const generatedScript = `<div style="display: flex; justify-content: center; align-items: center; height: 100vh; width: 100vw; overflow: hidden;">
  <iframe style="border: none; display: block; width: 100%;" id="${scriptId}" scrolling="no"></iframe>
</div>
<script>
function loadWidget() {
  const widget = new XMLHttpRequest();
  widget.open('GET', '${process.env.REACT_APP_API_SERVER}/api/GuestMarketing/form?id=${scriptId}');
  widget.send();
  widget.onload = function () {
    const response = JSON.parse(widget.responseText);
    const htmlTemplate = response.data.data;
    const iframe = document.getElementById('${scriptId}');
    const doc = iframe.contentWindow.document;

    doc.open();
    doc.write(htmlTemplate);
    doc.close();

    setTimeout(() => {
      const height = doc.documentElement.scrollHeight;
      iframe.style.height = height + 'px';
    }, 100);
  };
}
loadWidget();
</script>`
    setScriptGenerated(generatedScript)
  }, [scriptId])

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(scriptGenerated)
      .then(() => toast.success('Code copied to clipboard!'))
      .catch((err) => console.error('Failed to copy text:', err))
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Let&apos;s Install The Widget!</Typography>
          <MUIButton onClick={onClose} startIcon={<CloseIcon />} />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography align="center" marginBottom={2}>
          Place code from below wherever you want the widget to appear on your
          page
        </Typography>
        <Box
          component="pre"
          sx={{
            backgroundColor: '#2b2b2b',
            color: '#e2e2e2',
            fontFamily: 'Courier New, monospace',
            fontSize: '14px',
            padding: '15px',
            borderRadius: '5px',
            overflowX: 'auto',
            marginBottom: '20px',
          }}
        >
          {scriptGenerated}
        </Box>
        <MUIButton
          variant="contained"
          color="primary"
          onClick={handleCopyToClipboard}
          fullWidth
        >
          Copy to Clipboard
        </MUIButton>
      </DialogContent>
    </Dialog>
  )
}

export default GenerateScriptDialog
