import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import {
  Bill,
  BillActive,
  Down,
  Establishments,
  EstablishmentsActive,
  FastabLogo,
  FoodDelivery,
  FoodDeliveryActive,
  Guest,
  GuestActive,
  Home,
  HomeActive,
  IcPromoCode,
  IcPromoCodeHover,
  MobileLogo,
  MoneyBag,
  MoneyBagActive,
  Payment,
  PaymentActive,
  Payout,
  PayoutActive,
  QRCode,
  QRCodeActive,
  Report,
  ReportActive,
  RestaurantMenu,
  RestaurantMenuActive,
  Reviews,
  ReviewsActive,
  Setting,
  SettingActive,
  SquareUser,
  SquareUserActive,
  User,
  UserActive,
} from 'assets/images'
import Select from 'components/Select/Index'
import CryptoJS from 'crypto-js'
import {
  selectedEstablishment,
  setCurrentEstablishment,
  setEstablishments,
  setSelectedEstablishment,
} from 'features/establishment/establishment'
import {
  selectedRestaurant,
  setRestaurants,
  setSelectedRestuarant,
} from 'features/restaurant/restaurant'
import { IEstablishmentSearchResponse } from 'interfaces/establishment'
import { IRestuarantSearchResponse } from 'interfaces/restaurant'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import establishmentService from 'services/establishment.service'
import restaurantService from 'services/restaurant.service'
import { ROLE_SECRET_KEY } from 'utility/constants'

interface IGetExistingData {
  takeRestaurantList?: IRestuarantSearchResponse[]
  takeEstablishmentList?: IEstablishmentSearchResponse[]
}

function Sidebar({
  takeRestaurantList,
  takeEstablishmentList,
}: IGetExistingData) {
  let decrypted
  let roleId
  const isGMEnable = localStorage.getItem('isGMEnable')

  if (localStorage.getItem('roleId')) {
    decrypted = CryptoJS.AES.decrypt(
      localStorage.getItem('roleId'),
      ROLE_SECRET_KEY
    )
    roleId = +decrypted.toString(CryptoJS.enc.Utf8)
  }

  const admin = roleId === 1
  const adminOperator = roleId === 2
  const restaurantOperator = roleId === 4
  const restaurantAdmin = roleId === 3
  const locationOperator = roleId === 6
  const locationAdmin = roleId === 7
  const allowAllAcess =
    admin ||
    restaurantOperator ||
    restaurantAdmin ||
    locationOperator ||
    locationAdmin ||
    adminOperator

  const allowGMAccess = isGMEnable === 'true' ? allowAllAcess : admin

  useEffect(() => {
    if (document.body.classList.contains('sidebar-toggle')) {
      document.body.classList.remove('sidebar-toggle')
    }
    if (document.body.classList.contains('mouse-enter')) {
      document.body.classList.remove('mouse-enter')
    }
  }, [])
  const handleMouseEnter = () => {
    if (document.body.classList.contains('sidebar-toggle')) {
      document.body.classList.add('mouse-enter')
    }
  }

  const handleMouseLeave = () => {
    if (document.body.classList.contains('sidebar-toggle')) {
      document.body.classList.remove('mouse-enter')
    }
  }
  const navigate = useNavigate()
  // for filters
  const [restaurantList, setRestaurantList] = useState([])
  const [locationList, setLocationList] = useState([])
  const [establishmentLists, setEstablishmentList] = useState([
    {
      key: 'All',
      value: 0,
    },
  ])
  const selectedRestraunt = useSelector(selectedRestaurant)
  const [IsReviewShow, SetReviewShow] = useState(false)
  const selectedEstablishmentCurrent = useSelector(selectedEstablishment)
  const [selectedCurrentEstablishment, setCurrentSelectedEstablishment] =
    useState(selectedEstablishmentCurrent)

  const currentEstablishmentId = useSelector(selectedEstablishment)
  const dispatch = useDispatch()

  const getEstablishmentList = useCallback(
    async () => {
      try {
        const useEstablishmentList =
          takeEstablishmentList === null || takeEstablishmentList === undefined
            ? (
                await establishmentService.getAllEstablishments({
                  pageNo: 1,
                  pageSize: 0,
                  sortGridModels: [{ field: 'restaurantName', sort: 0 }],
                  restaurantId: selectedRestraunt,
                })
              ).data.data.data
            : takeEstablishmentList
        // const establishments = data.data.data.data
        if (selectedRestraunt !== null && selectedRestraunt !== 0) {
          const data = useEstablishmentList
            .filter((x) => x.restaurantId === selectedRestraunt)
            .map((e) => {
              return e
            })
          const list = data.map((e) => {
            return {
              key: e.establishmentName,
              value: e.establishmentId,
            }
          })
          list.splice(0, 0, {
            key: 'All',
            value: 0,
          })
          if (list.length === 2) {
            setSelectedEstablishment(list[1])
            dispatch(
              setSelectedEstablishment({ selectedEstablishment: list[1].value })
            )
          }
          setLocationList(list)
          let checkReview = true
          if (currentEstablishmentId !== 0 && currentEstablishmentId !== null) {
            useEstablishmentList.map((e) => {
              if (currentEstablishmentId === e.establishmentId) {
                checkReview = e.isShowReview
              }
              return e
            })
          } else {
            checkReview = !useEstablishmentList
              .filter((x) => x.restaurantId === selectedRestraunt)
              .some((e) => e.isShowReview === false)
          }
          SetReviewShow(checkReview)

          setEstablishmentList(list)
          if (
            !checkReview &&
            window.location.pathname === '/reviews' &&
            !admin
          ) {
            navigate('/dashboard')
          }
        } else {
          const list = [
            {
              key: 'All',
              value: 0,
            },
          ]
          setEstablishmentList(list)
          SetReviewShow(false)
          if (window.location.pathname === '/reviews' && !admin) {
            navigate('/dashboard')
          }
        }
        setLocationList(useEstablishmentList)
        dispatch(setEstablishments({ establishmentList: useEstablishmentList }))
      } catch (e) {
        if (localStorage.length !== 0) {
          if (localStorage.length !== 0) {
            toast.error('Failed to retrieve establishment list.')
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRestraunt]
  )

  const handleShowHideReview = () => {
    let isShow = true
    if (currentEstablishmentId !== 0) {
      locationList.map((e) => {
        if (currentEstablishmentId === e.establishmentId) {
          isShow = e.isShowReview
        }
        return e
      })
    } else {
      isShow = !locationList.some((e) => e.isShowReview === false)
    }
    SetReviewShow(isShow)
    if (!isShow && window.location.pathname === '/reviews' && !admin) {
      navigate('/dashboard')
    }
  }
  useEffect(() => {
    handleShowHideReview()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEstablishmentId])
  // get all the restraunts
  const getRestaurantList = useCallback(
    async () => {
      const useRestaurantList =
        takeRestaurantList === null || takeRestaurantList === undefined
          ? (
              await restaurantService.getAllResturants({
                pageNo: 1,
                pageSize: 0,
                sortGridModels: [
                  {
                    field: 'name',
                    sort: 0,
                  },
                ],
              })
            ).data.data.data
          : takeRestaurantList
      const list = useRestaurantList.map((e) => {
        return {
          key: e.name,
          value: e.restaurantId,
        }
      })
      list.splice(0, 0, {
        key: 'All',
        value: 0,
      })
      setRestaurantList(list)
      dispatch(setRestaurants({ restaurantList: useRestaurantList }))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRestraunt]
  )

  useEffect(() => {
    getEstablishmentList()
    setCurrentSelectedEstablishment(selectedEstablishmentCurrent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRestraunt, selectedEstablishmentCurrent])

  useEffect(() => {
    getRestaurantList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelectValue = (value: number) => {
    dispatch(setSelectedEstablishment({ selectedEstablishment: null }))
    dispatch(
      setCurrentEstablishment({
        currentEstablishments: null,
      })
    )
    dispatch(setSelectedRestuarant({ selectedRestaurant: value }))
    setCurrentSelectedEstablishment(null)
  }

  const handleSelectLocationValue = (value: number) => {
    setCurrentSelectedEstablishment(value)
    dispatch(setSelectedEstablishment({ selectedEstablishment: value }))
  }

  return (
    <>
      <aside
        className="sidebar"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Link to="/dashboard" className="logo">
          <img src={FastabLogo} alt="FasTab" className="fastab-logo" />
          <img src={MobileLogo} className="mobile-logo" alt="FasTab" />
        </Link>
        <div className="form-group">
          <Select
            label="All Restaurants"
            items={restaurantList}
            formikValue={selectedRestraunt}
            handleSelectValue={(value) => handleSelectValue(value)}
            searchAble
          />
        </div>
        <div className="form-group">
          <Select
            label="All Locations"
            items={establishmentLists}
            formikValue={selectedCurrentEstablishment}
            handleSelectValue={(value) => {
              handleSelectLocationValue(value)
            }}
            searchAble
          />
        </div>
        <ul className="main-navigation">
          {allowAllAcess && (
            <li>
              <NavLink title="Dashboard" to="/dashboard">
                <img src={Home} className="default" alt="Home" />
                <img src={HomeActive} className="active" alt="Home" />
                <span>Dashboard</span>
              </NavLink>
            </li>
          )}
          {/* <li>
            <NavLink title="Menu Manager" to="/menu-manager">
              <img
                src={RestaurantMenu}
                className="default"
                alt="Menu Manager"
              />
              <img
                src={RestaurantMenuActive}
                className="active"
                alt="Menu Manager"
              />
              <span>Menu Manager</span>
            </NavLink>
          </li> */}
          {allowGMAccess && (
            <li>
              <NavLink title="Guest Marketing" to="/guest-marketing">
                <img src={SquareUser} className="default" alt="User" />
                <img src={SquareUserActive} className="active" alt="User" />
                <span>Guest Marketing</span>
              </NavLink>
            </li>
          )}
          {admin && (
            <li>
              <NavLink title="Restaurants" to="/restaurants">
                <img
                  src={FoodDelivery}
                  className="default"
                  alt="Food Delivery"
                />
                <img
                  src={FoodDeliveryActive}
                  className="active"
                  alt="Food Delivery"
                />
                <span>Restaurants</span>
              </NavLink>
            </li>
          )}
          {admin && (
            <li>
              <NavLink title="Locations" to="/locations">
                <img
                  src={Establishments}
                  className="default"
                  alt="Establishments"
                />
                <img
                  src={EstablishmentsActive}
                  className="active"
                  alt="Locations"
                />
                <span>Locations</span>
              </NavLink>
            </li>
          )}
          {allowAllAcess && (
            <li>
              <NavLink title="Menu" to="/menu">
                <img
                  src={RestaurantMenu}
                  className="default"
                  alt="Restaurant Menu"
                />
                <img
                  src={RestaurantMenuActive}
                  className="active"
                  alt="Restaurant Menu"
                />
                <span>Menu</span>
              </NavLink>
            </li>
          )}
          {admin && (
            <li>
              <NavLink title="QR Codes" to="/qr-codes">
                <img src={QRCode} className="default" alt="QR Code" />
                <img src={QRCodeActive} className="active" alt="QR Code" />
                <span>QR Codes</span>
              </NavLink>
            </li>
          )}
          {admin && (
            <li>
              <NavLink title="Users" to="/users">
                <img src={User} className="default" alt="User" />
                <img src={UserActive} className="active" alt="User" />
                <span>Users</span>
              </NavLink>
            </li>
          )}
          {admin && (
            <li>
              <NavLink title="Promo Code" to="/promocode">
                <img src={IcPromoCode} className="default" alt="promocode" />
                <img
                  src={IcPromoCodeHover}
                  className="active"
                  alt="promocode"
                />
                <span>Promo Code</span>
              </NavLink>
            </li>
          )}
          {allowAllAcess && (
            <li>
              <NavLink title="Tabs" to="/tabs">
                <img src={Bill} className="default" alt="Bill" />
                <img src={BillActive} className="active" alt="Bill" />
                <span>Tabs</span>
              </NavLink>
            </li>
          )}
          <li style={admin || IsReviewShow ? {} : { display: 'none' }}>
            <NavLink title="Reviews" to="/reviews">
              <img src={Reviews} className="default" alt="Reviews" />
              <img src={ReviewsActive} className="active" alt="Reviews" />
              <span>Reviews</span>
            </NavLink>
          </li>

          {allowAllAcess && (
            <li>
              <NavLink title="Payments" to="/payments">
                <img src={Payment} className="default" alt="Payment" />
                <img src={PaymentActive} className="active" alt="Payment" />
                <span>Payments</span>
              </NavLink>
            </li>
          )}
          {admin && (
            <li>
              <NavLink title="Disbursements" to="/disbursement">
                <img src={MoneyBag} className="default" alt="Money Bag" />
                <img src={MoneyBagActive} className="active" alt="Money Bag" />
                <span>Disbursements</span>
              </NavLink>
            </li>
          )}
          {allowAllAcess && (
            <li>
              <NavLink title="Payouts" to="/payouts">
                <img src={Payout} className="default" alt="Money Bag" />
                <img src={PayoutActive} className="active" alt="Money Bag" />
                <span>Payouts</span>
              </NavLink>
            </li>
          )}
          {allowAllAcess && (
            <li>
              <NavLink title="Statements" to="/monthly-statements">
                <img src={Report} className="default" alt="Report" />
                <img src={ReportActive} className="active" alt="Report" />
                <span>Statements</span>
              </NavLink>
            </li>
          )}
          {admin && (
            <li>
              <NavLink title="Guests" to="/guests">
                <img src={Guest} className="default" alt="Guest" />
                <img src={GuestActive} className="active" alt="Guest" />
                <span>Guests</span>
              </NavLink>
            </li>
          )}
          {admin && (
            <li>
              <Accordion>
                <AccordionSummary
                  expandIcon={<img src={Down} alt="Down Arrow" />}
                >
                  <img src={Setting} className="default" alt="Setting" />
                  <img src={SettingActive} className="active" alt="Setting" />
                  <span>Configuration</span>
                </AccordionSummary>
                <AccordionDetails>
                  <ul>
                    <li>
                      <NavLink to="/pos" title="POS">
                        POS
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/currency" title="Currency">
                        Currency
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/country" title="Country">
                        Country
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/state" title="State">
                        State
                      </NavLink>
                    </li>
                  </ul>
                </AccordionDetails>
              </Accordion>
            </li>
          )}
        </ul>
      </aside>
    </>
  )
}

export default Sidebar
