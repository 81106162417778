import React, { useRef, useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
} from '@mui/material'
import { Close, Upload } from 'assets/images'
import { toast } from 'react-toastify'

interface DialogProps {
  open: boolean
  onClose: () => void
  onSubmit: (data: { contactSource: string; file: File | null }) => void
}

const FileUploadDialog: React.FC<DialogProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const [contactSource, setContactSource] = useState('Added Contacts')
  const [file, setFile] = useState<File | null>(null)
  const fileInputRef = useRef(null)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0])
    }
  }

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleSubmit = () => {
    if (!contactSource.trim()) {
      toast.error('Contact Source is required.')
      return
    }
    if (!file) {
      toast.error('File is required.')
      return
    }
    onSubmit({ contactSource, file })
    setContactSource('')
    setFile(null)
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      className="primary-dialog"
    >
      <DialogTitle>
        <h5>Upload Guest Data</h5>
        <Button
          variant="text"
          title="Close"
          onClick={onClose}
          className="icon-btn rounded"
          color="inherit"
        >
          <img src={Close} alt="Close" />
        </Button>
      </DialogTitle>
      <DialogContent>
        <div style={{ marginBottom: '16px' }}>
          <TextField
            fullWidth
            label="Contact Source"
            variant="outlined"
            value={contactSource}
            onChange={(e) => setContactSource(e.target.value)}
          />
        </div>
        <div>
          <input
            accept=".csv, .xlsx"
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          <Button
            variant="contained"
            color="primary"
            title="Select File"
            size="large"
            onClick={handleButtonClick}
          >
            <img src={Upload} alt="Upload" />
            <span>Select File</span>
          </Button>
          {file && <p>Selected file: {file.name}</p>}
        </div>
      </DialogContent>
      <DialogActions>
        <div className="btn-list">
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Upload
          </Button>
          <Button variant="outlined" color="secondary" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default FileUploadDialog
